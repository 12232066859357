@import "../../styles/breakpoints.scss";

.container {
  border-top: 1px solid #808285;
  cursor: pointer;
  background-color: #e7e7e7;
  font-family: "PlutoSansDPDLight";
  z-index: 100;
}

.even {
  background: white;
}

.summary {
  padding: 20px 0;
  font-size: 14px;
  line-height: 18px;
  //pointer-events: none;
}

.row {
  display: flex;
}

.marker {
  width: 16.6666666667%;
  text-align: center;
}
.address {
  width: 58.3333333333%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-size: 14px;
  line-height: 18px;

  strong {
    display: block;
    text-transform: uppercase;
    font-family: "PlutoSansDPD";
  }
}
.distance {
  font-size: 12px;
  font-weight: bold;
  padding-top: 20px;
  padding-left: 0;
  padding-right: 0.625rem;
  text-align: right !important;
  width: 25%;

  img {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }

  span {
    font-size: 12px;
    font-weight: bold;
  }
}

.locationIcons {
  text-align: center;
  padding-bottom: 20px;
}

.icons {
  width: 20%;
  vertical-align: middle;
  width: 30px;
  margin: 0 10px;
}

.locationOpening {
  div {
    table {
      padding-bottom: 1.25rem;
      font-size: 10px;
      width: 100%;

      thead {
        tr {
          td {
            font-family: "PlutoSansDPD";
            font-weight: bold;
          }
        }
      }

      tbody {
        tr {
          padding: 0 1px;
          line-height: 0.9rem;

          td:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}

.buttonCon {
  width: 100%;
  text-align: right;
}

.button {
  background-color: #424143;
  background: #424143;
  color: #fff;
  padding: 5px 15px;
  border-style: solid;
  border-width: 0px;
  cursor: pointer;
  font-family: "PlutoSansDPDLight";
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  border-color: #007095;
}

.heading {
  font-size: 10px;
  font-family: "PlutoSansDPD";
  padding: 0.25rem 0.1rem;
}
