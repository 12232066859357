@import "../../styles/breakpoints.scss";

.subFooter {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.line {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  border: 0;
  height: 1px;
  background: #808285;
  margin-top: 50px;
  margin-bottom: 20px;
}

.container {
  display: flex;
  font-family: "PlutoSansDPDLight";
}

.col1 {
  width: 25%;
  flex: 0 0 auto;
  @include media-breakpoint-down(lg) {
    width: 33.3333333333%;
  }
}
.col2 {
  width: 25%;
  flex: 0 0 auto;
  @include media-breakpoint-down(lg) {
    width: 33.3333333333%;
  }
}
.col3 {
  width: 25%;
  flex: 0 0 auto;
  @include media-breakpoint-down(lg) {
    width: 33.3333333333%;
  }
}
.col4 {
  width: 25%;
  flex: 0 0 auto;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.h3 {
  color: #424143;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 4px;
  font-weight: 200;
}

.li {
  line-height: 18px;
  list-style: none;
}

.subAnchor {
  font-size: 0.8125rem;
  color: #424143;

  &:hover {
    color: #dc0032;
  }
}

.customerService {
  background-color: #e6e7e7;
  color: #424143;
  padding: 15px 10px 10px 10px;
  margin-top: 13px;
}

.customerH3 {
  font-size: 1rem;
  color: #424143;
  margin-bottom: 20px;
  font-weight: 200;
  margin-top: 10px;
}

.customerP {
  font-size: 0.8125rem;
  margin-bottom: 20px;
  line-height: 1.6;
}

.customerAnchor {
  color: #c1181b;
  font-weight: bold;
}

.customerAnchorLocal {
  &:hover {
    color: #009adf;
  }
}
