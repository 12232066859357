@import "../../styles/breakpoints.scss";

.lanRow {
  width: 33.3333333333%;
  background: #ededed;
  padding: 8px;
  margin: 0;
  border: none;
  float: left;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.label {
  font-size: 0.875rem;
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0;
  font-family: "PlutoSansDPDLight";

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.selectorContainer {
  background-color: #fafafa;
  font-size: 0.875rem;
  font-family: "PlutoSansDPDExtraLight";
  font-weight: bold;
  padding: 7px;
  text-decoration: none;
  position: relative;
  // border: solid 2px transparent;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  height: 2.3125rem;

  &:hover {
    cursor: pointer;
    border: 2px solid #0060b6;
    border-radius: 5px;
  }
}

.countryContainer {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #33acff;
    color: white;
  }
}

.highlight {
  background-color: #33acff;
  color: white;
}

.displayNone {
  display: none !important;
}

.flagIcon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
  height: 14px;
  vertical-align: middle;

  @include media-breakpoint-down(sm) {
    width: 2.333333em;
  }
}

.flagGB {
  background-image: url("../../styles/flags/gb.svg");
}

.arrowDown {
  border: 4px solid transparent;
  border-top-color: #333;
  content: "";
  height: 0;
  margin-top: -1px;
  position: absolute;
  right: 8px;
  top: 50%;
  width: 0;
}

.langSelector {
  list-style: none;
  border-style: solid;
  border-color: #8bade4;
  border-width: 1px;
  background-color: #f3f3f3;
  font-size: 0.875rem;
  font-family: "PlutoSansDPDExtraLight";
  font-weight: bold;
  padding: 8px;
  z-index: 100;
  position: absolute;
  display: block;
  top: 33px;
  left: -1px;
  width: 101%;

  @include media-breakpoint-down(lg) {
    width: 100.5%;
  }
}
