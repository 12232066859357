@import "../../styles/breakpoints.scss";

.filterContainer {
  height: 600px;
  overflow-y: auto;
  width: 33.3333333333%;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.filterLists {
  display: none;
}

.pickupFilter {
  width: 100%;
  background: #424143;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterTitle {
  font-family: "PlutoSansDPDLight";
  font-size: 1rem;
}

.icons {
  float: right;
  i {
    font-family: "PlutoSansDPDLight";
    font-size: 25px;
    line-height: 25px;
    font-style: normal;
    display: block;
  }
}

.filterContent {
  background: #dfe1e1;
  padding: 15px 20px;
}

.showAll {
  background-color: #424143;
  background: #424143;
  color: #fff;
  padding: 5px 15px;
  width: 100px;
  border-style: solid;
  border-width: 0px;
  cursor: pointer;
  font-family: "PlutoSansDPDLight";
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  border-color: #007095;
}

.filterLabel {
  font-family: "PlutoSansDPDLight";
  display: inline-block;
  margin-left: 0.5rem;
  margin-bottom: 0;
  vertical-align: baseline;
  font-size: 0.875rem;
  color: #4d4d4d;
  cursor: pointer;
  font-weight: normal;
  line-height: 1.5;
}

.filter {
  width: 100%;
  margin-bottom: 0;
}

.filterItem {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
