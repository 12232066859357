@import "../../styles/breakpoints.scss";

.modal {
  border: solid 1px #666666;
  box-shadow: 0 0 10px rgba(0, 0, 0, 40%);
  border-radius: 3px;
  background-color: #ffffff;
  width: 80%;
  max-width: 62.5rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0px;
  padding: 1.875rem;
  margin-top: 10px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.45);
  z-index: 1004;
  overflow-y: scroll;
}

.container {
  position: relative;
}

.cross {
  top: -15px;
  right: -10px;
  font-size: 30px;
  line-height: 25px;
  position: absolute;
  color: #aaaaaa;
  font-weight: bold;
  cursor: pointer;
  font-family: "PlutoSansDPD";
}

.img {
  height: 25px;
  margin: 15px;
}

.panel {
  background-color: #e6e7e7;
  padding: 0;
  margin-bottom: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  background: #f2f2f2;
  color: #333333;
  display: flex;
  font-family: "PlutoSansDPDLight";

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.map {
  width: 33.3333333333%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.details {
  width: 66.6666666667%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.title {
  line-height: 1;
  margin-bottom: 0.625rem;
  font-size: 20px;
  text-transform: uppercase;
  padding-top: 5px;
}

.row {
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.col1 {
  width: 33.3333333333%;

  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-bottom: 1rem;
  }

  div {
    margin-bottom: 20px;
    font-size: 14px;
  }
}

.col2 {
  width: 33.3333333333%;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.directions {
  display: flex;
  margin-bottom: 0 !important;

  img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  span {
    font-family: "PlutoSansDPD";
    font-weight: bolder;
    font-size: 12px;
  }
}

.getDirections {
  font-size: 12px;
}

.locationOpening {
  div {
    padding: 0;

    table {
      padding-bottom: 1.25rem;
      font-size: 10px;
      width: 100%;

      thead {
        tr {
          td {
            font-family: "PlutoSansDPD";
            font-weight: bold;
          }
        }
      }

      tbody {
        display: table-cell;
        tr {
          padding: 0 1px;
          line-height: 0.9rem;

          td {
            width: 60%;
          }

          td:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}

.row {
  display: flex;
}

.locationIcons {
  font-size: 14px;
  color: #828282;
  line-height: 40px;
  vertical-align: middle;
  padding-bottom: 10px;
  width: 33.3333333333%;
  font-family: "PlutoSansDPDLight";
  img {
    width: 30px;
    height: 30px;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}

.iconCon {
  display: flex;
  align-items: center;
  margin-left: auto;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
  }

  img {
    margin-left: 20px;
    margin-right: 30px;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.text {
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 15px;
  margin-right: 5px;
  font-family: "PlutoSansDPDLight";

  a {
    text-decoration: underline;
    color: #424143;
  }
}

.org {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  text-transform: uppercase;
  font-family: "PlutoSansDPD";
}
