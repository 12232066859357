.flagGB {
  background-image: url("./flags/gb.svg");
}

.flagNL {
  background-image: url("./flags/nl.svg");
}

.flagFR {
  background-image: url("./flags/fr.svg");
}

.flagDE {
  background-image: url("./flags/de.svg");
}

.flagES {
  background-image: url("./flags/es.svg");
}

.flagPT {
  background-image: url("./flags/pt.svg");
}

.flagEE {
  background-image: url("./flags/ee.svg");
}

.flagLV {
  background-image: url("./flags/lv.svg");
}

.flagLT {
  background-image: url("./flags/lt.svg");
}

.flagAT {
  background-image: url("./flags/at.svg");
}

.flagDK {
  background-image: url("./flags/dk.svg");
}

.flagSE {
  background-image: url("./flags/se.svg");
}

.flagFI {
  background-image: url("./flags/fi.svg");
}

.flagCZ {
  background-image: url("./flags/cz.svg");
}

.flagPL {
  background-image: url("./flags/pl.svg");
}

.flagSK {
  background-image: url("./flags/sk.svg");
}
