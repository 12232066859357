@import "../../styles/breakpoints.scss";

.pickupContainer {
  width: 83.3333333333%;
  padding-right: 0 !important;

  &:nth-child(1) {
    height: 18px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0 !important;
  }
}

.label {
  font-size: 0.875rem;
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0;
  font-family: "PlutoSansDPDLight";

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.selectorContainer {
  background-color: #fafafa;
  font-size: 0.875rem;
  font-family: "PlutoSansDPDExtraLight";
  font-weight: bold;
  padding: 7px;
  text-decoration: none;
  position: relative;
  // border: solid 2px transparent;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  height: 2.3125rem;

  &:hover {
    cursor: pointer;
    border: 2px solid #0060b6;
    border-radius: 5px;
  }
}

.textHeading {
  font-style: normal;
  color: #222222;
  text-rendering: optimizeLegibility;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  line-height: 1.4;
  font-size: 1.4375rem;
  font-family: "PlutoSansDPDLight";

  h4 {
    font-weight: 200;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1.125rem;
  }
}

.rowForm {
  width: 66.6666666667%;
  float: right;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.panel {
  background: #ededed;
  border: 0;
  padding: 8px;
  margin-bottom: 0;
  color: #333333;
  height: 73px;

  @include media-breakpoint-down(sm) {
    padding: 0;
    height: initial;
  }
}

.countryRow {
  width: 33.3333333333%;
  float: left;

  @include media-breakpoint-down(sm) {
    width: 41.6666666667%;
  }
}

.countrySelect {
  height: 2.3125rem;
  width: 100%;
  -webkit-appearance: none !important;
  background-color: #fafafa;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
  background-position: 100% center;
  background-repeat: no-repeat;
}

.postcodeRow {
  padding-left: 5px;
  width: 41.6666666667%;
  float: left;
  position: relative;

  @include media-breakpoint-down(sm) {
    width: 41.6666666667%;
    padding-left: 0;
  }
}

.postcodeContainer {
  display: flex;
  align-items: flex-end;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.postcodeInput {
  width: 83.3333333333%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.input {
  -webkit-appearance: none;
  background-color: #ffffff;
  font-family: inherit;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-shadow: inset 0 1px 2px rgbb(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-size: 0.875rem;
  margin: 0 0 1rem 0;
  padding: 0.5rem;
  height: 2.3125rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
  border-right: 0;
  box-shadow: none;
  padding-left: 30px;
  margin-bottom: 0;
  font-family: "PlutoSansDPDLight";
  font-weight: bold;

  &:focus {
    outline: 0;
    box-shadow: 0 0 5px #999999;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0.5rem;
    border-right: 1px solid #cccccc;
  }
}

.magniGlass {
  width: 16.6666666667%;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.glassImg {
  background: #fff;
  border-left: none;
  color: #333333;
  border-color: #cccccc;
  display: block;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  font-size: 0.875rem;
  height: 2.3125rem;
  line-height: 2.3125rem;
  border-left: 0px;

  img {
    height: 25px;
    display: inline-block;
    vertical-align: middle;
  }
}

.searchButton {
  padding-left: 5px;
  width: 25%;
  float: right;

  button {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    margin-top: 21px;
    background-color: #dc0032;
    height: 37px;
    line-height: 37px;
    color: #ffffff;
    border-width: 0px;
    cursor: pointer;
    font-family: "PlutoSansDPDExtraLight";
    font-size: 1rem;
    font-weight: bold;

    @include media-breakpoint-down(sm) {
      font-size: 0;
      margin-top: 0;
      background-image: url("../../styles/assets/magnifying-glass-white.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30px;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 16.6666666667%;
    padding-left: 0;
  }
}

.mapRow {
  display: flex;
  width: 100%;

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
  }
}

.mapContainer {
  width: 66.6666666667%;
  height: 600px;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.alert {
  display: block;
  position: relative;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 10px;
  color: #424143;
  background-color: #f5dede;
  border: 1px solid #e9b4b4;
  font-family: "PlutoSansDPDLight";
}

.close {
  position: absolute;
  top: 50%;
  right: 5px;
  background-color: transparent;
  color: #333333;
  font-size: 20px;
  line-height: 18px;
  margin-top: -10px;
  opacity: 0.3;
  padding: 0 6px 4px;
  border-style: solid;
  border-width: 0px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
}

.moveLeft {
  transform: translateX(-15.625rem);
}

.local {
  background-color: #009adf !important;
}
