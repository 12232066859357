.table {
  width: 100%;
  display: table;
  border: solid #dc0032 2px;
}

.mobileBox {
  background-color: #dc0032;
  background-image: none;
  color: #ffffff;
  padding: 10px;
  height: 100%;
  font-size: 14px;
  font-weight: 600;
  cursor: default;
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  font-weight: bold;
}

.mobileArrow {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ffffff;
  float: right;
}

.overlay {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #dc0032;
  margin-top: -24px;
  margin-left: -20px;
}

.inactive {
  background-color: #ffffff;
  color: #dc0032;
}

.mobileArrowUp {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #dc0032;
  border-top: none;
}

.overlayUp {
  padding-top: 28px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #ffffff;
  border-top: none;
  margin-left: -20px;
}
